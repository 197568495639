import React, { useEffect, useMemo, useState } from "react";
import { DEFAULT_PERMISSION, DEFAULT_TOKEN_SETTINGS, secretKey, wsPrefix } from "utils/configs";
import useWs from "hooks/useWs";
import { useSearchParams } from "react-router-dom";
import screenCastUtils, { screenCastRequestFormat } from "utils/event/ScreenCast";
import {
  GetLayoutMetricsResponse,
  NavigationHistoryResponse,
  NavigationTypes,
  ScreencastFrameMetadata,
  ScreenShotProps,
} from "utils/types";
import { networkUtils } from "utils/event/Network";
import { EmulationUtils } from "utils/event/Emulation";
import { SetEmitTouchEventsForMouseRequestConfiguration } from "utils/event/types";
import { inputModelUtils } from "utils/event/InputModel";
import { auditUtils } from "utils/event/Audit";
import { serviceWorkerUtils } from "utils/event/ServiceWorker";
import CryptoJS from "crypto-js";
import { pageUtils } from "utils/event/Pages";
import { tabsUtils } from "utils/event/Tabs";
import { getCurrentBrowserTabId, processClip } from "utils/helpers";

let id = 1;

const shortcuts: {
  [x: number]: (arg0?: Event | undefined) => boolean;
} = {};
export function setupFarmLiveView() {
  const [farmPermission, setFarmPermission] = useState(DEFAULT_PERMISSION);
  const [tokenSettings, setTokenSettings] = useState(DEFAULT_TOKEN_SETTINGS);
  const [isCasting, setIsCasting] = useState(false);
  const [metaData, setMetaData] = useState<ScreencastFrameMetadata>();
  const [imageZoom, setImageZoom] = useState(1);
  const [screenZoom, setScreenZoom] = useState(1);
  const [isTabCreated, setIsTabCreated] = useState(false);
  const [tabIsNavigating, setTabIsNavigating] = useState<string | undefined>();
  const [layoutMetrics, setLayoutMetrics] = useState<GetLayoutMetricsResponse | undefined>();
  const [navigationHistory, setNavigationHistory] = useState<NavigationHistoryResponse>({
    currentIndex: -1,
    entries: [],
  });

  const [query] = useSearchParams();
  const wsUrl = useMemo(() => (query.get("ws") ? wsPrefix + query.get("ws") : undefined), [query]);
  const token = useMemo(() => (query.get("token") ? query.get("token") : undefined), [query]);

  const ws = useWs(wsUrl);

  useEffect(() => {
    if (token && secretKey) {
      console.log(token);
      const decodeToken = decodeURIComponent(token);
      // Decrypt token
      const bytes = CryptoJS.AES.decrypt(decodeToken, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // setTokenSettings(decryptedData);
    }
  }, [token]);

  const changePermission = (permission: string, newValue: boolean) => {
    // @ts-ignore
    if (tokenSettings[permission] && permission !== "view") {
      setFarmPermission((prev) => ({ ...prev, [permission]: newValue }));
    }
  };

  const enableNetwork = () => {
    if (!ws) return;
    networkUtils.enableNetwork(ws, id);
    increaseId();
    networkUtils.setDebugAttach(ws, id);
    increaseId();
  };

  const enablePage = () => {
    if (!ws) return;
    screenCastUtils.enablePage(ws, id);
    increaseId();
    screenCastUtils.enableRuntime(ws, id);
    increaseId();
    screenCastUtils.enableDOM(ws, id);
    increaseId();
    screenCastUtils.enableCSS(ws, id);
    increaseId();
    screenCastUtils.enableDebugger(ws, id);
    increaseId();
    screenCastUtils.enableOverlay(ws, id);
    increaseId();
    screenCastUtils.setShowViewportSizeOnResizeOverlay(ws, id);
    increaseId();
    screenCastUtils.enableProfiler(ws, id);
    increaseId();
    EmulationUtils.setEmulatedMedia(ws, id);
    increaseId();
    EmulationUtils.setEmulatedVisionDeficiency(ws, id);
    increaseId();
    auditUtils.enableAudit(ws, id);
    increaseId();
    serviceWorkerUtils.enableServiceWorker(ws, id);
    increaseId();
    screenCastUtils.enableInspector(ws, id);
    increaseId();
    screenCastUtils.setAutoAttachTarget(ws, id);
    increaseId();
    screenCastUtils.setDiscoverTarget(ws, id);
    increaseId();
    screenCastUtils.setRemoteLocation(ws, id);
    increaseId();
  };

  const startCasting = () => {
    if (!ws) return;
    console.log(123);
    if (isCasting) return;
    setIsCasting(true);
    const maxImageDimension = 2048;
    const { width, height } = screenCastUtils.getViewportDimensions();
    screenCastUtils.startScreenCast(ws, id, {
      format: screenCastRequestFormat.Jpeg,
      quality: 80,
      maxWidth: Math.floor(Math.min(maxImageDimension, width)),
      maxHeight: Math.floor(Math.min(maxImageDimension, height)),
    });
    increaseId();
    screenCastUtils.showEmulationTouch(ws, id);
    increaseId();
    ws.send(JSON.stringify({ id: id, method: "Page.stopScreencast", params: {} }));
    increaseId();
    ws.send(
      JSON.stringify({
        id: id,
        method: "Emulation.setTouchEmulationEnabled",
        params: { enabled: false, maxTouchPoints: 1 },
      }),
    );
    increaseId();
    ws.send(JSON.stringify({ id: id, method: "Runtime.runIfWaitingForDebugger", params: {} }));
    increaseId();
    ws.send(
      JSON.stringify({
        id: id,
        method: "Emulation.setFocusEmulationEnabled",
        params: { enabled: false },
      }),
    );
    increaseId();

    screenCastUtils.startScreenCast(ws, id, {
      format: screenCastRequestFormat.Jpeg,
      quality: 80,
      maxWidth: Math.floor(Math.min(maxImageDimension, width)),
      maxHeight: Math.floor(Math.min(maxImageDimension, height)),
    });
    increaseId();
    ws.send(
      JSON.stringify({
        id: id,
        method: "Emulation.setTouchEmulationEnabled",
        params: { enabled: true, maxTouchPoints: 1 },
      }),
    );
    increaseId();
  };

  const stopCasting = () => {
    if (!isCasting || !ws) return;
    screenCastUtils.stopScreenCast(ws, id);
    increaseId();
    setIsCasting(false);
    ws?.close();
  };

  const screenCastFrame = (base64Data: string, metadata: ScreencastFrameMetadata) => {
    setMetaData(metadata);
    return "data:image/jpg;base64," + base64Data;
  };

  const listenScreenCastChange = (sessionId: number) => {
    if (!ws) return;
    screenCastUtils.screenCastFrame(ws, id, sessionId);
    increaseId();
  };

  const focusActiveTab = () => {
    if (!ws) return;
    screenCastUtils.bringToFront(ws, id);
    increaseId();
  };

  const reloadTab = () => {
    if (!ws) return;
    screenCastUtils.reloadTab(ws, id);
    increaseId();
    setTabIsNavigating(getCurrentBrowserTabId());
  };

  const requestListTabs = () => {
    if (!ws) return;
    screenCastUtils.requestListTabs(ws);
  };

  const calcZoomOnLoad = (evt: React.SyntheticEvent<HTMLImageElement>) => {
    if (!metaData) return;
    const { width, height } = screenCastUtils.getViewportDimensions(evt);
    console.log("evt", { evt, natural: evt.currentTarget.naturalWidth });
    const deviceSizeRatio = metaData.deviceHeight / metaData.deviceWidth;
    let _imageZoom = Math.min(
      width / evt.currentTarget.naturalWidth,
      height / (evt.currentTarget.naturalWidth * deviceSizeRatio),
    );
    console.log("pix", window.devicePixelRatio);
    if (_imageZoom < 1.01 / window.devicePixelRatio) {
      _imageZoom = 1 / window.devicePixelRatio;
    }
    const _screenZoom = (evt.currentTarget.naturalWidth * _imageZoom) / metaData.deviceWidth;
    console.log("_imageZoom", { _imageZoom, _screenZoom });
    setImageZoom(_imageZoom);
    setScreenZoom(_screenZoom);
  };

  const handleMouseEvent = (event: Event) => {
    if (!ws || !farmPermission.control) return;
    screenCastUtils.emitTouchFromMouseEvent(
      ws,
      id,
      event,
      metaData ? metaData.offsetTop : 0,
      screenZoom,
    );
    increaseId();
  };

  const increaseId = () => {
    id = id + 1;
  };

  const resendWithSessionId = (sessionId: string) => {
    if (!ws) return;
    networkUtils.enableNetwork(ws, id, sessionId);
    increaseId();
    networkUtils.setDebugAttach(ws, id, sessionId);
    increaseId();
    screenCastUtils.enableRuntime(ws, id, sessionId);
    increaseId();
    screenCastUtils.enableDebugger(ws, id, sessionId);
    increaseId();
    screenCastUtils.enableProfiler(ws, id, sessionId);
    increaseId();
    screenCastUtils.enableInspector(ws, id, sessionId);
    increaseId();
    screenCastUtils.setAutoAttachTarget(ws, id, sessionId);
    increaseId();
  };

  const initMouse = () => {
    if (!ws) return;
    // Event để init con chuột cho sự kiện click
    EmulationUtils.setEmitTouchEventsForMouse(ws, id, {
      enabled: false,
      configuration: SetEmitTouchEventsForMouseRequestConfiguration.Mobile,
    });
    increaseId();
    EmulationUtils.setEmitTouchEventsForMouse(ws, id, {
      enabled: true,
      configuration: SetEmitTouchEventsForMouseRequestConfiguration.Desktop,
    });
    increaseId();
  };

  const removeMouse = () => {
    if (!ws) return;
    // Event để init con chuột cho sự kiện click
    EmulationUtils.setEmitTouchEventsForMouse(ws, id, {
      enabled: false,
      configuration: SetEmitTouchEventsForMouseRequestConfiguration.Mobile,
    });
    increaseId();
    EmulationUtils.setEmitTouchEventsForMouse(ws, id, {
      enabled: false,
      configuration: SetEmitTouchEventsForMouseRequestConfiguration.Desktop,
    });
    increaseId();
  };

  const handleMouseEventWithEventListener = (event: Event) => {
    if (!ws) return;
    console.log(event);
    screenCastUtils.emitTouchFromMouseEventNyEventListener(
      ws,
      id,
      event,
      metaData ? metaData.offsetTop : 0,
      screenZoom,
    );
    increaseId();
  };

  const handleKeyEvent = (event: Event) => {
    if (!ws || !farmPermission.control) return;
    const shortcutKey = inputModelUtils.makeKeyFromEvent(event as KeyboardEvent);
    console.log("eventttt", { event, shortcutKey });

    const handler = shortcuts[shortcutKey];
    if (handler && handler(event)) {
      // @ts-ignore
      event.consume();
      return;
    }
    shortcuts[shortcutKey] = () => true;
    inputModelUtils.emitKeyEvent(ws, id, event);
    increaseId();
    shortcuts[shortcutKey] = () => false;
    // @ts-ignore
    event.consume();
  };

  const getNavigationHistory = () => {
    if (!ws) return;
    screenCastUtils.getNavigationHistory(ws, id);
    increaseId();
  };

  const captureScreenshot = (screenShotProps: ScreenShotProps) => {
    if (!ws) return;
    tabsUtils.activeTarget(ws, id, getCurrentBrowserTabId());
    increaseId();
    if (screenShotProps.fullScreen) {
      if (!layoutMetrics) return;
      const cssContentSize = layoutMetrics.cssContentSize || layoutMetrics.contentSize;
      const clip = processClip({ ...cssContentSize, scale: layoutMetrics.visualViewport.scale });
      screenCastUtils.captureScreenshot(ws, id, {
        format: screenShotProps.imageFormat,
        clip,
        captureBeyondViewport: true,
      });
      increaseId();
    } else {
      screenCastUtils.captureScreenshot(ws, id, {
        format: screenShotProps.imageFormat,
        captureBeyondViewport: true,
      });
      increaseId();
    }
  };

  const handleNavigate = (type: NavigationTypes) => {
    if (!ws || !farmPermission.navigate) return;
    console.log(navigationHistory);
    // getNavigationHistory();
    // setTabIsNavigating(getCurrentBrowserTabId());

    if (type === NavigationTypes.forward) {
      pageUtils.navigateToExactEntry(
        ws,
        id,
        navigationHistory.entries[navigationHistory.currentIndex + 1].id,
      );
      // pageUtils.navigateToExactURL(ws, id, {
      //   url: navigationHistory.entries[navigationHistory.currentIndex + 1].url,
      // });
      increaseId();
      getNavigationHistory();
      increaseId();
    } else if (type === NavigationTypes.back) {
      console.log("back", navigationHistory.entries[navigationHistory.currentIndex - 1]);
      pageUtils.navigateToExactEntry(
        ws,
        id,
        navigationHistory.entries[navigationHistory.currentIndex - 1].id,
      );
      // pageUtils.navigateToExactURL(ws, id, {
      //   url: navigationHistory.entries[navigationHistory.currentIndex - 1].url,
      // });
      increaseId();
      getNavigationHistory();
      increaseId();
    }
  };

  console.log(tabIsNavigating);

  const handleNavigateUrl = (url: string) => {
    if (!ws || !farmPermission.navigate) return;
    pageUtils.navigateToExactURL(ws, id, { url });
    increaseId();
    setTabIsNavigating(getCurrentBrowserTabId());
    getNavigationHistory();
    increaseId();
  };

  const closeTarget = (tabId: string) => {
    if (!ws || !farmPermission.control) return;
    tabsUtils.closeTarget(ws, id, tabId);
    increaseId();
  };

  const createTarget = () => {
    if (!ws || !farmPermission.control) return;
    tabsUtils.createTarget(ws, id);
    increaseId();
    setIsTabCreated(true);
  };

  const getLayoutMetrics = () => {
    if (!ws) return;
    pageUtils.getLayoutMetrics(ws, id);
    increaseId();
  };

  return {
    ws,
    isCasting,
    imageZoom,
    screenZoom,
    farmPermission,
    tokenSettings,
    navigationHistory,
    isTabCreated,
    tabIsNavigating,
    setIsTabCreated,
    startCasting,
    screenCastFrame,
    listenScreenCastChange,
    reloadTab,
    requestListTabs,
    focusActiveTab,
    stopCasting,
    calcZoomOnLoad,
    handleMouseEvent,
    enableNetwork,
    enablePage,
    resendWithSessionId,
    handleMouseEventWithEventListener,
    handleKeyEvent,
    changePermission,
    initMouse,
    removeMouse,
    getNavigationHistory,
    captureScreenshot,
    setNavigationHistory,
    handleNavigate,
    handleNavigateUrl,
    closeTarget,
    createTarget,
    setTabIsNavigating,
    setLayoutMetrics,
    getLayoutMetrics,
  };
}
