import axios from "axios";
import { getCurrentWsDomain } from "utils/helpers";
import { BrowserTabProps } from "utils/types";

const getListBrowserTabs = async (): Promise<BrowserTabProps[]> => {
  return axios
    .get<BrowserTabProps[]>("http://" + getCurrentWsDomain() + "/json", { timeout: 10000 })
    .then((res) => res.data.filter((tab) => tab.type === "page"));
};

export const farmServices = {
  getListBrowserTabs,
};
