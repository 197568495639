import React from "react";
import { CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { BrowserTabProps } from "utils/types";
import { useFarmContext } from "context/FarmContext";
import { ButtonIcon, Flex, Image } from "@kikisoftware/uikit";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DEFAULT_LOGO_PATH } from "utils/configs";

const Tab = (tab: BrowserTabProps & { isActive: boolean }) => {
  console.log(tab);
  const { changeTab, onBrowserTabChange, closeTab, tabIsNavigating } = useFarmContext();

  const onChangeTab = () => {
    onBrowserTabChange(tab.id);
    changeTab(tab.id);
  };

  return (
    <>
      <Flex
        sx={{
          width: 250,
          maxWidth: 250,
          overflow: "hidden",
          bgcolor: tab.isActive ? "white" : "transparent",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          px: 1,
          height: "100%",
          cursor: "default",
        }}
        alignItems="center"
        onClick={onChangeTab}
      >
        <Flex justify="flex-start" align="center" sx={{ gap: 1, width: "calc(100% - 24px)" }}>
          {tabIsNavigating === tab.id ? (
            <CircularProgress size="1rem" />
          ) : (
            <Image
              src={tab.faviconUrl ?? DEFAULT_LOGO_PATH}
              alt={tab.title}
              asyncLoading={false}
              sx={{ width: 18, height: 18 }}
            />
          )}

          <Typography fontSize={12} whiteSpace="nowrap" overflow="hidden">
            {tab.title}
          </Typography>
        </Flex>
        <ButtonIcon
          buttonType="circle"
          sx={{ minWidth: 24, width: 24, height: 24 }}
          onClick={closeTab(tab.id)}
        >
          <CloseOutlinedIcon sx={{ width: 14, height: 14 }} />
        </ButtonIcon>
      </Flex>
      {!tab.isActive && (
        <Divider orientation="vertical" flexItem sx={{ height: 18, alignSelf: "center" }} />
      )}
    </>
  );
};

function TabsBar() {
  const { currentBrowserTab, browserTabs, createTarget } = useFarmContext();
  return (
    <Stack
      direction="row"
      // divider={<Divider orientation="vertical" flexItem sx={{ height: 18, alignSelf: "center" }} />}
      sx={{ bgcolor: "#F1F3F4" }}
      height={37}
      px={1}
      alignItems="center"
    >
      {browserTabs.map((tab) => (
        <Tab key={tab.id} {...tab} isActive={currentBrowserTab === tab.id} />
      ))}
      <ButtonIcon
        buttonType="circle"
        sx={{ minWidth: 24, width: 24, height: 24, ml: 1 }}
        onClick={createTarget}
      >
        <AddOutlinedIcon sx={{ width: 18, height: 18 }} />
      </ButtonIcon>
    </Stack>
  );
}

export default TabsBar;
