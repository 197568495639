export const LANGUAGE_LIST = [
  {
    lang: "en",
    name: "English",
    icon: "/images/sidebar-icons/locales/flag-us.svg",
  },
  {
    lang: "vi",
    name: "Tiếng việt",
    icon: "/images/sidebar-icons/locales/flag-vn.svg",
  },
];
