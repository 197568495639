import React from "react";
import { alpha, styled, Typography } from "@mui/material";

const InactiveTabStyle = styled("div")({
  position: "fixed",
  top: 34,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: alpha("#35363A", 0.95),
  width: "100%",
  height: "calc(100% - 34px - 37px)",
  zIndex: 10000,
});

export const InformationBox = styled("div")({
  border: "1px solid #46474A",
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: 590,
  height: 150,
  backgroundColor: "#35363A",
});

function TabInactive() {
  return (
    <InactiveTabStyle>
      <InformationBox sx={{ width: { lg: 590, height: { lg: 112 } } }}>
        <Typography fontSize={26} color="white">
          This tab is inactive
        </Typography>
      </InformationBox>
    </InactiveTabStyle>
  );
}

export default TabInactive;
