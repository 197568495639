import { NAV_BAR_HEIGHT, wsMethod } from "utils/configs";
import {
  EmulateTouchFromMouseEventRequest,
  EmulateTouchFromMouseEventRequestType,
  MouseButton,
} from "utils/event/types";
import React from "react";
import { CaptureScreenshotRequest } from "utils/types";

export const screenCastRequestFormat = {
  Jpeg: "jpeg",
  Png: "png",
};

export type ScreenCastRequestFormat =
  typeof screenCastRequestFormat[keyof typeof screenCastRequestFormat];

const REQUEST_TABS_ID = 28;

export const mouseEventMapping = {
  wheel: "mouseWheel",
};

function getNavigationHistory(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageGetNavigationHistory }));
}

function enablePage(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageEnable, params: {} }));
}

function enableRuntime(ws: WebSocket, id: number, sessionId?: string) {
  ws.send(JSON.stringify({ id, method: wsMethod.runtimeEnable, params: {}, sessionId }));
}

function enableDebugger(ws: WebSocket, id: number, sessionId?: string) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.debuggerEnable,
      params: { maxScriptsCacheSize: 10000000 },
      sessionId,
    }),
  );
}

function enableDOM(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.domEnable,
      params: {},
    }),
  );
}

function enableCSS(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.cssEnable,
      params: {},
    }),
  );
}

function enableOverlay(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.overlayEnable,
      params: {},
    }),
  );
}

function setShowViewportSizeOnResizeOverlay(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.overlaySetShowViewportSizeOnResize,
      params: { show: true },
    }),
  );
}

function enableProfiler(ws: WebSocket, id: number, sessionId?: string) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.profilerEnable,
      params: {},
      sessionId,
    }),
  );
}

function enableInspector(ws: WebSocket, id: number, sessionId?: string) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.inspectorEnable,
      params: {},
      sessionId,
    }),
  );
}

function setAutoAttachTarget(ws: WebSocket, id: number, sessionId?: string) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.targetSetAutoAttach,
      params: { autoAttach: true, waitForDebuggerOnStart: true, flatten: true },
      sessionId,
    }),
  );
}

function setDiscoverTarget(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.targetSetDiscoverTargets,
      params: { discover: true },
    }),
  );
}

function setRemoteLocation(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.targetSetRemoteLocations,
      params: { locations: [{ host: "localhost", port: 9229 }] },
    }),
  );
}

function showEmulationTouch(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.emulationSetTouchEmulationEnabled,
      params: { enabled: true, maxTouchPoints: 1 },
    }),
  );
}

function getViewportDimensions(el?: React.SyntheticEvent<HTMLImageElement>) {
  if (el) {
    return {
      width: el.currentTarget.offsetWidth * window.devicePixelRatio,
      height: el.currentTarget.offsetHeight * window.devicePixelRatio,
    };
  }
  return {
    width: window.innerWidth * window.devicePixelRatio,
    height: (window.innerHeight - NAV_BAR_HEIGHT) * window.devicePixelRatio,
  };
}

function startScreenCast(
  ws: WebSocket,
  id: number,
  params: { format: ScreenCastRequestFormat; quality: number; maxWidth: number; maxHeight: number },
) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageStartScreenCast, params }));
}

function screenCastFrame(ws: WebSocket, id: number, sessionId: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageScreencastFrameAck, params: { sessionId } }));
}

function stopScreenCast(ws: WebSocket, id: number) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.pageStopScreenCast,
      params: {},
    }),
  );
}

function bringToFront(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageBringToFront, params: {} }));
}

function reloadTab(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageReload, params: {} }));
}

function requestListTabs(ws: WebSocket) {
  ws.send(JSON.stringify({ id: REQUEST_TABS_ID, method: wsMethod.targetGetTarget }));
}

function emitTouchFromMouseEvent(
  ws: WebSocket,
  id: number,
  event: Event,
  offsetTop: number,
  screenZoom: number,
) {
  let activeTouchOffsetTop = null;
  const buttons = ["left", "none", "right"] as MouseButton[];
  const types: { [key: string]: EmulateTouchFromMouseEventRequestType } = {
    mousedown: "mousePressed",
    mouseup: "mouseReleased",
    mousemove: "mouseMoved",
    wheel: "mouseWheel",
  };

  const eventType = event.type as string;

  const mouseEvent = event as MouseEvent;

  console.log("eventType", { mouseEvent, screenZoom });

  // if (eventType !== "wheel" && buttons[mouseEvent.which] === "none") {
  //   return;
  // }
  if (eventType === "mousedown" || activeTouchOffsetTop === null) {
    activeTouchOffsetTop = offsetTop;
  }

  const x = Math.round(mouseEvent.pageX / screenZoom);
  let y = Math.round(mouseEvent.pageY / screenZoom);
  y = Math.round(y - activeTouchOffsetTop - NAV_BAR_HEIGHT);
  const params: EmulateTouchFromMouseEventRequest = {
    type: types[eventType],
    x: x,
    y: y,
    modifiers: 0,
    button: buttons[mouseEvent.button],
    clickCount: 0,
  };
  if (event.type === "wheel") {
    const wheelEvent = mouseEvent as WheelEvent;
    params.deltaX = wheelEvent.deltaX / screenZoom;
    params.deltaY = -wheelEvent.deltaY / screenZoom;
  } else {
    // this.activeTouchParams = params;
  }
  console.log(params);
  ws.send(JSON.stringify({ id, method: wsMethod.inputEmulateTouchFromMouseEvent, params }));
}

function emitTouchFromMouseEventNyEventListener(
  ws: WebSocket,
  id: number,
  event: Event,
  offsetTop: number,
  zoom: number,
) {
  let activeTouchOffsetTop = null;
  const buttons = ["none", "left", "middle", "right"] as MouseButton[];
  const types: { [key: string]: EmulateTouchFromMouseEventRequestType } = {
    mousedown: "mousePressed",
    mouseup: "mouseReleased",
    mousemove: "mouseMoved",
    mousewheel: "mouseWheel",
  };

  const eventType = event.type as string;
  console.log({ eventType, types });
  if (!(eventType in types)) {
    console.log(111);
    return;
  }

  const mouseEvent = event as MouseEvent;

  if (!(mouseEvent.which in buttons)) {
    console.log(222);

    return;
  }
  if (eventType !== "mousewheel" && buttons[mouseEvent.which] === "none") {
    console.log(333);

    return;
  }

  if (eventType === "mousedown" || activeTouchOffsetTop === null) {
    activeTouchOffsetTop = offsetTop;
  }

  const x = Math.round(mouseEvent.offsetX / zoom);
  let y = Math.round(mouseEvent.offsetY / zoom);
  y = Math.round(y - activeTouchOffsetTop);
  const params: EmulateTouchFromMouseEventRequest = {
    type: types[eventType],
    x: x,
    y: y,
    modifiers: 0,
    button: buttons[mouseEvent.which],
    clickCount: 0,
  };
  if (event.type === "mousewheel") {
    const wheelEvent = mouseEvent as WheelEvent;
    params.deltaX = wheelEvent.deltaX / zoom;
    params.deltaY = -wheelEvent.deltaY / zoom;
  } else {
    // activeTouchParams = params;
  }
  if (event.type === "mouseup") {
    activeTouchOffsetTop = null;
  }
  console.log(params);
  ws.send(JSON.stringify({ id, method: wsMethod.inputEmulateTouchFromMouseEvent, params }));
}

function captureScreenshot(ws: WebSocket, id: number, params: CaptureScreenshotRequest) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageCaptureScreenshot, params }));
}

const screenCastUtils = {
  REQUEST_TABS_ID,
  startScreenCast,
  getViewportDimensions,
  screenCastFrame,
  bringToFront,
  reloadTab,
  requestListTabs,
  emitTouchFromMouseEvent,
  enablePage,
  enableRuntime,
  enableDebugger,
  showEmulationTouch,
  enableDOM,
  enableCSS,
  enableOverlay,
  setShowViewportSizeOnResizeOverlay,
  enableProfiler,
  setAutoAttachTarget,
  setDiscoverTarget,
  setRemoteLocation,
  stopScreenCast,
  enableInspector,
  emitTouchFromMouseEventNyEventListener,
  getNavigationHistory,
  captureScreenshot,
};

export default screenCastUtils;
