export type MouseButton = "none" | "left" | "right" | "middle";
export type EmulateTouchFromMouseEventRequestType =
  | "mousePressed"
  | "mouseReleased"
  | "mouseMoved"
  | "mouseWheel";

export interface EmulateTouchFromMouseEventRequest {
  /**
   * Type of the mouse event.
   */
  type: EmulateTouchFromMouseEventRequestType;
  /**
   * X coordinate of the mouse pointer in DIP.
   */
  x: number;
  /**
   * Y coordinate of the mouse pointer in DIP.
   */
  y: number;
  /**
   * Mouse button. Only "none", "left", "right" are supported.
   */
  button: MouseButton;
  /**
   * Time at which the event occurred (default: current time).
   */
  timestamp?: number;
  /**
   * X delta in DIP for mouse wheel event (default: 0).
   */
  deltaX?: number;
  /**
   * Y delta in DIP for mouse wheel event (default: 0).
   */
  deltaY?: number;
  /**
   * Bit field representing pressed modifier keys. Alt=1, Ctrl=2, Meta/Command=4, Shift=8
   * (default: 0).
   */
  modifiers?: number;
  /**
   * Number of times the mouse button was clicked (default: 0).
   */
  clickCount?: number;
}

export const enum SetEmitTouchEventsForMouseRequestConfiguration {
  Mobile = "mobile",
  Desktop = "desktop",
}

export interface SetEmitTouchEventsForMouseRequest {
  /**
   * Whether touch emulation based on mouse input should be enabled.
   */
  enabled: boolean;
  /**
   * Touch/gesture events configuration. Default: current platform.
   */
  configuration?: SetEmitTouchEventsForMouseRequestConfiguration;
}

export const enum DispatchKeyEventRequestType {
  KeyDown = "keyDown",
  KeyUp = "keyUp",
  RawKeyDown = "rawKeyDown",
  Char = "char",
}
