import React, { useMemo } from "react";
import { getHostnameByRegex } from "utils/helpers";

function UrlDisplay({ url }: { url: string }) {
  const hostname = useMemo(() => getHostnameByRegex(url), [url]);
  const pathname = useMemo(() => (hostname ? url.split(hostname)[1] : ""), [url, hostname]);
  return (
    <span className="url-display">
      {hostname}
      <span className="url-display pathname">{pathname}</span>
    </span>
  );
}

export default UrlDisplay;
