import { Viewport } from "utils/types";

export const getHostnameByRegex = (url: string) => {
  // run against regex
  const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1];
};

export function getHostName(url: string) {
  const hostname = getHostnameByRegex(url);
  if (!hostname) return "default";
  const list = hostname.split(".");
  return `${list[list.length - 2]}.${list[list.length - 1]}`;
}

export function getCurrentBrowserTabId() {
  const query = window.location.search;
  const startStr = "/devtools/page";
  if (query.indexOf("&") !== -1) {
    return query.substring(query.indexOf(startStr) + startStr.length + 1, query.indexOf("&"));
  }
  return query.substring(query.indexOf(startStr) + startStr.length + 1);
}

export function getCurrentWsHost() {
  const query = window.location.search;
  const startStr = "/devtools/page";
  return query.substring(0, query.indexOf(startStr) + startStr.length + 1);
}

export function getCurrentWsDomain() {
  const query = window.location.search;
  const startStr = "/devtools/page";
  return query.substring(4, query.indexOf(startStr));
}

export function downloadImageFromBase64(base64Data: string, contentType: string, fileName: string) {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export function processClip(clip: Viewport): Viewport {
  const x = Math.round(clip.x);
  const y = Math.round(clip.y);
  const width = Math.round(clip.width + clip.x - x);
  const height = Math.round(clip.height + clip.y - y);
  return { x: 0, y: 0, width, height, scale: clip.scale };
}
