import { wsMethod } from "utils/configs";
import { NavigateRequest } from "utils/types";

function navigateToExactEntry(ws: WebSocket, id: number, entryId: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageNavigateToHistoryEntry, params: { entryId } }));
}

function navigateToExactURL(ws: WebSocket, id: number, params: NavigateRequest) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageNavigate, params }));
}

function closeTab(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageClose }));
}

function getLayoutMetrics(ws: WebSocket, id: number) {
  ws.send(JSON.stringify({ id, method: wsMethod.pageGetLayoutMetrics }));
}

export const pageUtils = {
  navigateToExactEntry,
  navigateToExactURL,
  closeTab,
  getLayoutMetrics,
};
