import React from "react";
import UrlBar from "components/UrlBar";
import TabsBar from "components/TabsBar";

function Header() {
  return (
    <>
      <TabsBar />
      <UrlBar />
    </>
  );
}

export default Header;
