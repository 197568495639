import React from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/vi";
import enMessages from "./messages/en.json";
import viMessages from "./messages/vi.json";
import { useLang } from "i18n/I18nContext";

export const allMessages = {
  en: enMessages,
  vi: viMessages,
};

export function I18nProvider({ children }: React.PropsWithChildren) {
  const { lang } = useLang();
  const messages = allMessages[lang as keyof typeof allMessages];

  return (
    <IntlProvider locale={lang} messages={messages} onError={() => {}}>
      {children}
    </IntlProvider>
  );
}
