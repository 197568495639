import React from "react";
import { alpha, Button, styled, Typography } from "@mui/material";
import { InformationBox } from "components/TabInactive";

const InactiveTabStyle = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: alpha("#35363A", 0.95),
  width: "100%",
  height: "calc(100% - 37px)",
  zIndex: 10000,
});

function TabDisconnected() {
  return (
    <InactiveTabStyle>
      <InformationBox>
        <Typography fontSize={16} color="white">
          KikiLogin cannot connect to this profile. Reason:{" "}
          <Typography component="span" fontSize={16} color="#DC3545">
            {" "}
            WebSocket disconnected
          </Typography>
        </Typography>
        <Typography fontSize={16} color="white">
          Check the network, make sure the profile is opening or reopening this window.
        </Typography>
        <Button
          onClick={() => window.location.reload()}
          sx={{ color: "white", bgcolor: "transparent", border: "1px solid white", mt: 2 }}
        >
          Reconnect KikiLogin Profile
        </Button>
      </InformationBox>
    </InactiveTabStyle>
  );
}

export default TabDisconnected;
