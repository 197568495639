import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { InformationBox } from "components/TabInactive";
import { Input } from "@kikisoftware/uikit";

function NoWsBoundary() {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ bgcolor: "#35363A", height: "100vh" }}>
      <InformationBox>
        <Typography fontSize={24} color="white">
          No Websocket url found!
        </Typography>
        <Typography fontSize={18} color="white">
          Please enter Websocket URL here to connect live view{" "}
        </Typography>
        <Box px={4} width={1} mt={1}>
          <Input
            placeholder="Input socket url here"
            sx={{ input: { color: "white" }, "& fieldset": { borderColor: "white" } }}
          />
        </Box>
      </InformationBox>
    </Stack>
  );
}

export default NoWsBoundary;
