import { Input, MenuPopover } from "@kikisoftware/uikit";
import React, { useRef, useState } from "react";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { ButtonIconStyle } from "components/UrlBar";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { useFarmContext } from "context/FarmContext";
import SearchIcon from "@mui/icons-material/Search";
import SimpleBar from "simplebar-react";

function HistoryPopover() {
  const { navigationHistory, getNavigationHistory, handleNavigateUrl } = useFarmContext();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  console.log(navigationHistory);
  const handleOpen = () => {
    getNavigationHistory();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const navigateHistoryPage = (url: string) => {
    handleNavigateUrl(url);
    handleClose();
  };

  return (
    <>
      <ButtonIconStyle
        startIcon={<HistoryOutlinedIcon sx={{ color: "#637380" }} />}
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Typography variant="textSm" color="#637380">
          History
        </Typography>
      </ButtonIconStyle>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400, px: 1.5, pt: 1, pb: 1.5 }}
      >
        <Input
          placeholder="Tìm kiếm lịch sử"
          value={searchText}
          onChange={onSearchChange}
          sx={{ mb: 1, width: 1, "& fieldset": { borderColor: "rgba(139, 146, 153, 0.3)" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#9ca5ae", opacity: 0.8, fontSize: 22 }} />
              </InputAdornment>
            ),
          }}
        />
        <SimpleBar
          style={{
            height: 260,
            border: "1px solid rgba(139, 146, 153, 0.3)",
            borderRadius: "4px",
          }}
        >
          <Stack>
            {navigationHistory.entries
              .filter(
                (history) =>
                  history.title.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1 ||
                  history.url.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1 ||
                  history.userTypedURL.toLowerCase().indexOf(searchText.toLowerCase().trim()) !==
                    -1,
              )
              .reverse()
              .map((history, idx) => (
                <React.Fragment key={idx}>
                  <Stack
                    width={1}
                    sx={{
                      overflow: "hidden",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "#f4f4f4" },
                      p: 1,
                    }}
                    onClick={() => navigateHistoryPage(history.url)}
                  >
                    {/* <Tooltip title={history.title} placement="bottom-start">*/}
                    <Typography
                      variant="textBase"
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {history.title}
                    </Typography>
                    {/* </Tooltip>*/}
                    {/* <Tooltip title={history.url} placement="bottom-start">*/}
                    <Typography
                      variant="textSm"
                      color="#8B9299"
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: 1 }}
                    >
                      {history.url}
                    </Typography>
                    {/* </Tooltip>*/}
                  </Stack>
                </React.Fragment>
              ))}
          </Stack>
        </SimpleBar>
      </MenuPopover>
    </>
  );
}

export default HistoryPopover;
