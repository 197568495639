import React, { useEffect, useMemo, useRef } from "react";
import UrlDisplay from "components/UrlDisplay";
import { Button, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonIcon, Image } from "@kikisoftware/uikit";
import { DEFAULT_LOGO_PATH } from "utils/configs";
import { useFarmContext } from "context/FarmContext";
import PermissionPopover from "components/PermissionPopover";
import ScreenShotPopover from "components/ScreenShotPopover";
import HistoryPopover from "components/HistoryPopover";
import { NavigationTypes } from "utils/types";
import { getHostnameByRegex } from "utils/helpers";

const UrlBarStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  padding: "12px 6px",
  height: 37,
});

const UrlStyle = styled("div")({
  backgroundColor: "#F1F3F4",
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 20,
  height: 28,
  padding: "0 10px",
  margin: "0 4px",
  width: "100%",
  overflow: "hidden",
  fontSize: 14,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  "&:focus": {
    outlineColor: "#204DC2",
  },
});

export const ButtonIconStyle = styled(Button)({
  borderRadius: 20,
});

const iconStyle = { width: 20, height: 20 };
const buttonIconStyle = { minWidth: 29, width: 29, height: 29 };

const UrlBar = () => {
  const {
    reloadTab,
    currentBrowserTab,
    browserTabs,
    screenshotRef,
    navigationHistory,
    handleNavigate,
    handleNavigateUrl,
  } = useFarmContext();
  const urlRef = useRef<HTMLDivElement | null>(null);

  const tab = useMemo(
    () => browserTabs.find((tab) => tab.id === currentBrowserTab),
    [browserTabs, currentBrowserTab],
  );

  useEffect(() => {
    if (tab?.url && urlRef.current) {
      const hostname = getHostnameByRegex(tab.url);
      const pathname = hostname ? tab.url.split(hostname)[1] : "";
      // urlRef.current.textContent = tab.url;
      urlRef.current.innerHTML = `<span class="url-display">${hostname}<span class="url-display pathname">${pathname}</span></span>`;
    }
  }, [tab?.url]);

  const navigateToKiki = () => {
    handleNavigateUrl("https://kikilogin.com");
  };

  const pressKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    console.log(e);
    if (!urlRef.current) return;
    if (e.key === "Enter") {
      const url = urlRef.current.textContent;
      if (url) {
        const validUrl = url.startsWith("http") ? url : `https://${url}`;
        handleNavigateUrl(validUrl);
      }
    }
  };

  return (
    <UrlBarStyle>
      <ButtonIcon
        buttonType="circle"
        sx={buttonIconStyle}
        onClick={() => handleNavigate(NavigationTypes.back)}
        disabled={navigationHistory.currentIndex === 0}
      >
        <ArrowBackIcon sx={iconStyle} />
      </ButtonIcon>
      <ButtonIcon
        buttonType="circle"
        sx={buttonIconStyle}
        onClick={() => handleNavigate(NavigationTypes.forward)}
        // disabled={navigationHistory.currentIndex === navigationHistory.entries.length - 1}
      >
        <ArrowForwardIcon sx={iconStyle} />
      </ButtonIcon>
      <ButtonIcon buttonType="circle" sx={buttonIconStyle} onClick={reloadTab}>
        <RefreshIcon sx={iconStyle} />
      </ButtonIcon>
      <ButtonIcon buttonType="circle" sx={buttonIconStyle} onClick={navigateToKiki}>
        <Image
          src={DEFAULT_LOGO_PATH}
          alt="kiki"
          asyncLoading={false}
          sx={{ width: 18, height: 20, mt: -0.2 }}
        />
      </ButtonIcon>
      <UrlStyle contentEditable ref={urlRef} onKeyUp={pressKey}>
        <UrlDisplay url={tab?.url ?? ""} />
      </UrlStyle>

      <PermissionPopover />
      <ScreenShotPopover ref={screenshotRef} />
      <HistoryPopover />
    </UrlBarStyle>
  );
};

export default UrlBar;
