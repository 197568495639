import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useFarmContext } from "context/FarmContext";

function ViewPort() {
  const ref = useRef<any>();
  const { farmPermission, frameImg, calcZoomOnLoad, handleMouseEvent, handleKeyEvent, ws } =
    useFarmContext();

  useEffect(() => {
    const screenImg = document.getElementById("screen-capture");
    console.log("sc", screenImg);
    if (screenImg) {
      screenImg.tabIndex = 0;
      // screenImg.addEventListener("mousedown", handleMouseEventWithEventListener);
      // screenImg.addEventListener("mouseup", handleMouseEventWithEventListener);
      // screenImg.addEventListener("mousemove", handleMouseEventWithEventListener);
      // screenImg.addEventListener("mousewheel", handleMouseEventWithEventListener);
      // screenImg.addEventListener("keydown", handleKeyEvent, false);
      // screenImg.addEventListener("keyup", handleKeyEvent, false);
      // screenImg.removeEventListener("keypress", handleKeyEvent, false);
      // screenImg.addEventListener("keypress", handleKeyEvent, false);
    }
  }, [frameImg]);

  useEffect(() => {
    if (ws && farmPermission.control) {
      document.addEventListener("keydown", handleKeyEvent, false);
      document.addEventListener("keyup", handleKeyEvent, false);
      document.addEventListener("keypress", handleKeyEvent, false);
    } else {
      document.removeEventListener("keydown", handleKeyEvent, false);
      document.removeEventListener("keyup", handleKeyEvent, false);
      document.removeEventListener("keypress", handleKeyEvent, false);
    }
  }, [ws, farmPermission.control]);

  return (
    <Box sx={{ bgcolor: "#35363A", height: "calc(100vh - 111px)", overflowY: "hidden" }}>
      {frameImg && (
        <img
          id="screen-capture"
          ref={ref}
          src={frameImg}
          alt="logo"
          onWheel={(evt) => handleMouseEvent(evt as unknown as Event)}
          onMouseDown={(evt) => handleMouseEvent(evt as unknown as Event)}
          onMouseUp={(evt) => handleMouseEvent(evt as unknown as Event)}
          onLoad={(evt) => calcZoomOnLoad(evt)}
          // onKeyDown={(evt) => console.log(123)}
          tabIndex={0}
        />
      )}
    </Box>
  );
}

export default ViewPort;
