import { wsMethod } from "utils/configs";

const enableNetwork = (ws: WebSocket, id: number, sessionId?: string) => {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.networkEnabled,
      params: { maxPostDataSize: 65536 },
      sessionId,
    }),
  );
};

const setDebugAttach = (ws: WebSocket, id: number, sessionId?: string) => {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.networkSetAttachDebugStack,
      params: { enabled: true },
      sessionId,
    }),
  );
};

export const networkUtils = {
  enableNetwork,
  setDebugAttach,
};
