import { wsMethod } from "utils/configs";

function closeTarget(ws: WebSocket, id: number, targetId: string) {
  ws.send(JSON.stringify({ id, method: wsMethod.targetCloseTarget, params: { targetId } }));
}

function createTarget(ws: WebSocket, id: number, url = "https://www.google.com/") {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.targetCreateTarget,
      params: { url },
    }),
  );
}

function activeTarget(ws: WebSocket, id: number, targetId: string) {
  ws.send(
    JSON.stringify({
      id,
      method: wsMethod.targetActivateTarget,
      params: { targetId },
    }),
  );
}

export const tabsUtils = { closeTarget, createTarget, activeTarget };
