const operationSystem = {
  android: "Android",
  ios: "iOS",
  windowPhone: "Windows Phone",
  unknown: "unknown",
};

/**
 * Determine the mobile operating system.
 *
 * @returns {String}
 */
function getMobileOperatingSystem(): string {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/windows phone/i.test(userAgent)) {
    return operationSystem.windowPhone;
  }

  if (/android/i.test(userAgent)) {
    return operationSystem.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return operationSystem.ios;
  }

  return operationSystem.unknown;
}

function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i;
  let os = null;

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (!os && /linux/.test(userAgent)) {
    os = "linux";
  }

  return os;
}

const isMac = () => getOS() === "macos";

export const systemUtils = {
  operationSystem,
  getMobileOperatingSystem,
  isMac,
};
