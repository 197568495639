import { BrowserTabProps, CaptureScreenshotRequestFormat, ScreenShotProps } from "utils/types";

export const secretKey = process.env.REACT_APP_SECRET_KEY;

export const wsMethod = {
  networkEnabled: "Network.enable",
  networkSetAttachDebugStack: "Network.setAttachDebugStack",
  pageEnable: "Page.enable",
  pageStartScreenCast: "Page.startScreencast",
  pageStopScreenCast: "Page.stopScreencast",
  pageScreencastFrameAck: "Page.screencastFrameAck",
  pageScreencastFrame: "Page.screencastFrame",
  pageNavigateToHistoryEntry: "Page.navigateToHistoryEntry",
  pageNavigate: "Page.navigate",
  pageReload: "Page.reload",
  pageBringToFront: "Page.bringToFront",
  pageClose: "Page.close",
  targetCloseTarget: "Target.closeTarget",
  targetCreateTarget: "Target.createTarget",
  targetTargetCreated: "Target.targetCreated",
  pageGetNavigationHistory: "Page.getNavigationHistory",
  pageCaptureScreenshot: "Page.captureScreenshot",
  targetGetTarget: "Target.getTargets",
  pageScreencastVisibilityChanged: "Page.screencastVisibilityChanged",
  inputEmulateTouchFromMouseEvent: "Input.emulateTouchFromMouseEvent",
  inputDispatchKeyEvent: "Input.dispatchKeyEvent",
  runtimeEnable: "Runtime.enable",
  debuggerEnable: "Debugger.enable",
  emulationSetTouchEmulationEnabled: "Emulation.setTouchEmulationEnabled",
  domEnable: "DOM.enable",
  cssEnable: "CSS.enable",
  overlayEnable: "Overlay.enable",
  overlaySetShowViewportSizeOnResize: "Overlay.setShowViewportSizeOnResize",
  profilerEnable: "Profiler.enable",
  targetSetAutoAttach: "Target.setAutoAttach",
  targetSetDiscoverTargets: "Target.setDiscoverTargets",
  targetAttachedToTarget: "Target.attachedToTarget",
  targetSetRemoteLocations: "Target.setRemoteLocations",
  inspectorEnable: "Inspector.enable",
  emulationSetEmitTouchEventsForMouse: "Emulation.setEmitTouchEventsForMouse",
  emulationSetEmulatedMedia: "Emulation.setEmulatedMedia",
  emulationSetEmulatedVisionDeficiency: "Emulation.setEmulatedVisionDeficiency",
  auditsEnable: "Audits.enable",
  serviceWorkerEnable: "ServiceWorker.enable",
  networkLoadingFinished: "Network.loadingFinished",
  pageGetLayoutMetrics: "Page.getLayoutMetrics",
  targetActivateTarget: "Target.activateTarget",
};

export const wsPrefix = "ws://";
export const DEFAULT_LOGO_PATH = "/statics/icons/kiki-small-icon.png";
export const NAV_BAR_HEIGHT = 74;

export const DEFAULT_PERMISSION = {
  view: true,
  control: false,
  navigate: false,
};

export const DEFAULT_TOKEN_SETTINGS = {
  view: true,
  control: true,
  navigate: true,
  email: "",
  profileName: "unknown",
};

export const DEFAULT_SCREENSHOT_CONFIG: ScreenShotProps = {
  imageFormat: CaptureScreenshotRequestFormat.Png,
  fullScreen: false,
};

export const defaultTab: BrowserTabProps = {
  id: "123",
  faviconUrl: "https://www.google.com/favicon.ico",
  title: "Google",
  type: "page",
  url: "https://www.google.com/",
  isDraft: true,
};
