import { DispatchKeyEventRequestType } from "utils/event/types";
import { systemUtils } from "utils/systems";
import { wsMethod } from "utils/configs";

function makeKeyFromCodeAndModifiers(keyCode: number, modifiers: number | null): number {
  return (keyCode & 255) | ((modifiers || 0) << 8);
}

export const Modifiers: {
  [x: string]: number;
} = {
  None: 0,
  Shift: 1,
  Ctrl: 2,
  Alt: 4,
  Meta: 8,
  // "default" command/ctrl key for platform, Command on Mac, Ctrl on other platforms
  CtrlOrMeta: systemUtils.isMac() ? 8 /* Meta */ : 2 /* Ctrl */,
  // Option on Mac, Shift on other platforms
  ShiftOrOption: systemUtils.isMac() ? 4 /* Alt */ : 1 /* Shift */,
};

const makeKeyFromEvent = (keyboardEvent: KeyboardEvent): number => {
  let modifiers = Modifiers.None;
  if (keyboardEvent.shiftKey) {
    modifiers |= Modifiers.Shift;
  }
  if (keyboardEvent.ctrlKey) {
    modifiers |= Modifiers.Ctrl;
  }
  if (keyboardEvent.altKey) {
    modifiers |= Modifiers.Alt;
  }
  if (keyboardEvent.metaKey) {
    modifiers |= Modifiers.Meta;
  }

  // Use either a real or a synthetic keyCode (for events originating from extensions).
  // @ts-ignore ExtensionServer.js installs '__keyCode' on some events.
  const keyCode = keyboardEvent.keyCode || keyboardEvent["__keyCode"];
  return makeKeyFromCodeAndModifiers(keyCode, modifiers);
};

const modifiersForEvent = (event: KeyboardEvent) => {
  return (
    (event.altKey ? 1 : 0) |
    (event.ctrlKey ? 2 : 0) |
    (event.metaKey ? 4 : 0) |
    (event.shiftKey ? 8 : 0)
  );
};

const emitKeyEvent = (ws: WebSocket, id: number, event: Event) => {
  let type: DispatchKeyEventRequestType;
  switch (event.type) {
    case "keydown":
      type = DispatchKeyEventRequestType.KeyDown;
      break;
    case "keyup":
      type = DispatchKeyEventRequestType.KeyUp;
      break;
    case "keypress":
      type = DispatchKeyEventRequestType.Char;
      break;
    default:
      return;
  }
  const keyboardEvent = event as KeyboardEvent;
  const text = event.type === "keypress" ? String.fromCharCode(keyboardEvent.charCode) : undefined;
  const params = {
    type: type,
    modifiers: modifiersForEvent(keyboardEvent),
    text: text,
    unmodifiedText: text ? text.toLowerCase() : undefined,
    keyIdentifier: (keyboardEvent as { keyIdentifier?: string }).keyIdentifier,
    code: keyboardEvent.code,
    key: keyboardEvent.key,
    windowsVirtualKeyCode: keyboardEvent.keyCode,
    nativeVirtualKeyCode: keyboardEvent.keyCode,
    autoRepeat: false,
    isKeypad: false,
    isSystemKey: false,
  };
  console.log(params);
  ws.send(JSON.stringify({ id, method: wsMethod.inputDispatchKeyEvent, params }));
};

export const inputModelUtils = {
  emitKeyEvent,
  makeKeyFromEvent,
};
