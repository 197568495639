import React from "react";
import { styled } from "@mui/material";
import { Image } from "@kikisoftware/uikit";

const WaterMarkRootStyle = styled("div")({
  position: "fixed",
  bottom: 50,
  right: 20,
  zIndex: 100000,
  pointerEvents: "none",
  opacity: 0.1,
});

function WaterMark() {
  return (
    <WaterMarkRootStyle>
      <Image
        src="/statics/icons/kiki-logo.png"
        asyncLoading={false}
        sx={{
          pointerEvents: "none",
          width: 260,
          webkitFilter: "grayscale(100%)" /* Safari 6.0 - 9.0 */,
          filter: "grayscale(100%)",
        }}
      />
    </WaterMarkRootStyle>
  );
}

export default WaterMark;
