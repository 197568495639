import React from "react";
import "./App.css";
import Header from "components/Header";
import FarmContextProvider from "context/FarmContext";
import ViewPort from "components/ViewPort";
import { useSearchParams } from "react-router-dom";
import NoWsBoundary from "components/NoWsBoundary";
import Footer from "components/Footer";
import WaterMark from "components/WaterMark";

function App() {
  const [query] = useSearchParams();

  if (!query.get("ws")) {
    return <NoWsBoundary />;
  }

  return (
    <FarmContextProvider>
      <Header />
      <ViewPort />
      <Footer />
      <WaterMark />
    </FarmContextProvider>
  );
}

export default App;
