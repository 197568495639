import React, { createContext, useContext, useState } from "react";
import { LANGUAGE_LIST } from "i18n/constants";
import { I18nProps } from "i18n/types";

export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";
const initialState = {
  selectedLang: "vi",
  onChangeLang: () => {},
  lang: "vi",
  langProps: LANGUAGE_LIST[1],
};

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      // console.error(er);
    }
  }
  return initialState;
}

export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
}

const I18nContext = createContext<I18nProps>(initialState);

export function useLang() {
  return useContext(I18nContext);
}

export function withI18n(Component: React.ComponentType<any>) {
  class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithI18n;
}

export const I18nConsumer = I18nContext.Consumer;

export function KikiI18nProvider({ children }: React.PropsWithChildren) {
  const [lang, setLang] = useState(getConfig().selectedLang);

  const onChangeLang = async (newLang: string) => {
    if (newLang !== lang) {
      setLang(newLang);
      setLanguage(newLang);
    }
  };

  return (
    <I18nContext.Provider
      value={{ lang, onChangeLang, langProps: LANGUAGE_LIST.find((x) => x.lang === lang) }}
    >
      {children}
    </I18nContext.Provider>
  );
}
