import { MenuPopover } from "@kikisoftware/uikit";
import React, { useRef, useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { ButtonIconStyle } from "components/UrlBar";
import { useFarmContext } from "context/FarmContext";
import useTranslation from "hooks/useTranslation";

function PermissionPopover() {
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { farmPermission, changePermission, tokenSettings } = useFarmContext();

  console.log(farmPermission);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ButtonIconStyle
        startIcon={<SettingsOutlinedIcon sx={{ color: "#637380" }} />}
        sx={{ mx: 1 }}
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Typography variant="textSm" color="#637380">
          Permission
        </Typography>
      </ButtonIconStyle>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 200, px: 2, py: 1 }}
      >
        {/* <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{title?.columnsAdjust}</Typography>*/}
        <FormGroup>
          {Object.entries(farmPermission).map(([key, value]) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={value}
                  onChange={() => changePermission(key, !value)}
                  sx={{ cursor: "pointer" }}
                  size="small"
                />
              }
              label={t(key)}
              sx={{
                width: "100%",

                m: 0,
                "& .MuiCheckbox-root": {
                  padding: 0.75,
                },
                "& .MuiTypography-root": {
                  fontSize: 14,
                },
                cursor: !tokenSettings[key] || key === "view" ? "not-allowed" : "pointer",
                opacity: !tokenSettings[key] || key === "view" ? 0.5 : 1,
                "&:hover": {
                  bgcolor:
                    !tokenSettings[key] || key === "view" ? "transparent" : "rgba(0, 0, 0, 0.08)",
                },
              }}
            />
          ))}
        </FormGroup>
      </MenuPopover>
    </>
  );
}

export default PermissionPopover;
