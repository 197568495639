import React, { useContext } from "react";
import { FarmProps } from "utils/types";
import { getCurrentBrowserTabId } from "utils/helpers";
import { farmLiveViewListener } from "utils/farmLiveViewListener";
import TabInactive from "components/TabInactive";
import TabDisconnected from "components/TabDisconnected";
import { DEFAULT_PERMISSION, DEFAULT_TOKEN_SETTINGS } from "utils/configs";

const initialProps = {
  currentBrowserTab: getCurrentBrowserTabId(),
  onBrowserTabChange: () => {},
  tabIsNavigating: undefined,
  faviconList: {},
  isFocusing: true,
  isDisconnected: false,
  browserTabs: [],
  navigationHistory: { currentIndex: -1, entries: [] },
  tokenSettings: DEFAULT_TOKEN_SETTINGS,
  changeTab: () => {},
  calcZoomOnLoad: () => {},
  screenZoom: 1,
  imageZoom: 1,
  handleMouseEvent: () => {},
  handleMouseEventWithEventListener: () => {},
  handleKeyEvent: () => {},
  farmPermission: DEFAULT_PERMISSION,
  changePermission: () => {},
  initMouse: () => {},
  removeMouse: () => {},
  getNavigationHistory: () => {},
  captureScreenshot: () => {},
  handleNavigate: () => {},
  handleNavigateUrl: () => {},
  closeTab: () => () => {},
  createTarget: () => {},
  getLayoutMetrics: () => {},
};

const farmContext = React.createContext<FarmProps>(initialProps);

function FarmContextProvider({ children }: React.PropsWithChildren) {
  const farmLiveViewConfig = farmLiveViewListener();

  return (
    <farmContext.Provider value={{ ...farmLiveViewConfig }}>
      {farmLiveViewConfig.isDisconnected ? (
        <TabDisconnected />
      ) : (
        !farmLiveViewConfig.isFocusing && <TabInactive />
      )}
      {children}
    </farmContext.Provider>
  );
}

export const useFarmContext = () => useContext(farmContext);

export default FarmContextProvider;
