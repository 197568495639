import { SetEmitTouchEventsForMouseRequest } from "utils/event/types";
import { wsMethod } from "utils/configs";

const setEmitTouchEventsForMouse = (
  ws: WebSocket,
  id: number,
  params: SetEmitTouchEventsForMouseRequest,
) => {
  ws.send(JSON.stringify({ id, method: wsMethod.emulationSetEmitTouchEventsForMouse, params }));
};

const setEmulatedMedia = (ws: WebSocket, id: number) => {
  ws.send(
    JSON.stringify({
      id: id,
      method: "Emulation.setEmulatedMedia",
      params: {
        media: "",
        features: [
          { name: "color-gamut", value: "" },
          { name: "prefers-color-scheme", value: "" },
          { name: "forced-colors", value: "" },
          { name: "prefers-contrast", value: "" },
          { name: "prefers-reduced-data", value: "" },
          { name: "prefers-reduced-motion", value: "" },
        ],
      },
    }),
  );
};

const setEmulatedVisionDeficiency = (ws: WebSocket, id: number) => {
  ws.send(
    JSON.stringify({
      id: id,
      method: "Emulation.setEmulatedVisionDeficiency",
      params: { type: "none" },
    }),
  );
};

export const EmulationUtils = {
  setEmitTouchEventsForMouse,
  setEmulatedMedia,
  setEmulatedVisionDeficiency,
};
