// export interface BrowserTabProps {
//   targetId: string;
//   type: string;
//   title: string;
//   url: string;
//   attached: boolean;
//   canAccessOpener: boolean;
//   browserContextId: string;
// }

import React, { Ref } from "react";
import { DOM, Page } from "generated/protocol";
import LayoutViewport = Page.LayoutViewport;

export interface BrowserTabProps {
  description?: string;
  devtoolsFrontendUrl?: string;
  id: string;
  title: string;
  type: string;
  url: string;
  webSocketDebuggerUrl?: string;
  faviconUrl?: string;
  index?: number;
  isDraft?: boolean;
}

export type Permission = "view" | "control" | "navigate";

export interface ScreenshotRef {
  downloadScreenshot: (base64Data: string) => void;
}

export interface FarmProps {
  ws?: WebSocket;
  currentBrowserTab?: string;
  onBrowserTabChange: (tab: string) => void;
  frameImg?: string;
  reloadTab?: () => void;
  isFocusing: boolean;
  isDisconnected: boolean;
  browserTabs: BrowserTabProps[];
  changeTab: (tabId: string) => void;
  imageZoom: number;
  screenZoom: number;
  calcZoomOnLoad: (evt: React.SyntheticEvent<HTMLImageElement>) => void;
  handleMouseEvent: (event: Event) => void;
  handleMouseEventWithEventListener: (event: Event) => void;
  handleKeyEvent: (event: Event) => void;
  farmPermission: Record<Permission, boolean>;
  changePermission: (permission: string, newValue: boolean) => void;
  initMouse: () => void;
  removeMouse: () => void;
  getNavigationHistory: () => void;
  navigationHistory: NavigationHistoryResponse;
  captureScreenshot: (screenshotProps: ScreenShotProps, clip?: Viewport) => void;
  screenshotRef?: Ref<ScreenshotRef> | null;
  tokenSettings: any;
  handleNavigate: (type: NavigationTypes) => void;
  handleNavigateUrl: (url: string) => void;
  closeTab: (tabId: string) => (e: React.MouseEvent) => void;
  createTarget: () => void;
  tabIsNavigating?: string;
  getLayoutMetrics: () => void;
}

export interface ScreencastFrameMetadata {
  /**
   * Top offset in DIP.
   */
  offsetTop: number;
  /**
   * Page scale factor.
   */
  pageScaleFactor: number;
  /**
   * Device screen width in DIP.
   */
  deviceWidth: number;
  /**
   * Device screen height in DIP.
   */
  deviceHeight: number;
  /**
   * Position of horizontal scroll in CSS pixels.
   */
  scrollOffsetX: number;
  /**
   * Position of vertical scroll in CSS pixels.
   */
  scrollOffsetY: number;
  /**
   * Frame swap timestamp.
   */
  timestamp?: number;
}

export interface ScreenShotProps {
  imageFormat: CaptureScreenshotRequestFormat;
  fullScreen: boolean;
}

export interface NavigationHistoryProps {
  id: number;
  title: string;
  transitionType: string;
  url: string;
  userTypedURL: string;
}

export interface NavigationHistoryResponse {
  currentIndex: number;
  entries: NavigationHistoryProps[];
}

export interface Viewport {
  /**
   * X offset in device independent pixels (dip).
   */
  x: number;
  /**
   * Y offset in device independent pixels (dip).
   */
  y: number;
  /**
   * Rectangle width in device independent pixels (dip).
   */
  width: number;
  /**
   * Rectangle height in device independent pixels (dip).
   */
  height: number;
  /**
   * Page scale factor.
   */
  scale: number;
}

export const enum CaptureScreenshotRequestFormat {
  Jpeg = "jpeg",
  Png = "png",
  Webp = "webp",
}

export interface CaptureScreenshotRequest {
  /**
   * Image compression format (defaults to png).
   */
  format?: CaptureScreenshotRequestFormat;
  /**
   * Compression quality from range [0..100] (jpeg only).
   */
  quality?: number;
  /**
   * Capture the screenshot of a given region only.
   */
  clip?: Viewport;
  /**
   * Capture the screenshot from the surface, rather than the view. Defaults to true.
   */
  fromSurface?: boolean;
  /**
   * Capture the screenshot beyond the viewport. Defaults to false.
   */
  captureBeyondViewport?: boolean;
}

export interface CaptureScreenshotResponse {
  /**
   * Base64-encoded image data.
   */
  data: any;
}

export const enum NavigationTypes {
  forward = "forward",
  back = "back",
}

/**
 * Transition type.
 */
export const enum TransitionType {
  Link = "link",
  Typed = "typed",
  Address_bar = "address_bar",
  Auto_bookmark = "auto_bookmark",
  Auto_subframe = "auto_subframe",
  Manual_subframe = "manual_subframe",
  Generated = "generated",
  Auto_toplevel = "auto_toplevel",
  Form_submit = "form_submit",
  Reload = "reload",
  Keyword = "keyword",
  Keyword_generated = "keyword_generated",
  Other = "other",
}

export interface NavigateRequest {
  /**
   * URL to navigate the page to.
   */
  url: string;
  /**
   * Referrer URL.
   */
  referrer?: string;
  /**
   * Intended transition type.
   */
  transitionType?: TransitionType;
  /**
   * Frame id to navigate, if not specified navigates the top frame.
   */
  frameId?: any;
  /**
   * Referrer-policy used for the navigation.
   */
  referrerPolicy?: ReferrerPolicy;
}

export interface GetLayoutMetricsResponse {
  /**
   * Deprecated metrics relating to the layout viewport. Can be in DP or in CSS pixels depending on the `enable-use-zoom-for-dsf` flag. Use `cssLayoutViewport` instead.
   */
  layoutViewport: LayoutViewport;
  /**
   * Deprecated metrics relating to the visual viewport. Can be in DP or in CSS pixels depending on the `enable-use-zoom-for-dsf` flag. Use `cssVisualViewport` instead.
   */
  visualViewport: VisualViewport;
  /**
   * Deprecated size of scrollable area. Can be in DP or in CSS pixels depending on the `enable-use-zoom-for-dsf` flag. Use `cssContentSize` instead.
   */
  contentSize: DOM.Rect;
  /**
   * Metrics relating to the layout viewport in CSS pixels.
   */
  cssLayoutViewport: LayoutViewport;
  /**
   * Metrics relating to the visual viewport in CSS pixels.
   */
  cssVisualViewport: VisualViewport;
  /**
   * Size of scrollable area in CSS pixels.
   */
  cssContentSize: DOM.Rect;
}
