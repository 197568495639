import { useIntl } from "react-intl";

export default function useTranslation() {
  const intl = useIntl();

  const t = (id?: string) => {
    if (!id) {
      return undefined;
    }
    return intl.formatMessage({ id: id });
  };

  return { t };
}
