import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SettingsProvider, KikiThemeProvider } from "@kikisoftware/uikit";
import "simplebar/dist/simplebar.min.css";
import "simplebar-react/dist/simplebar.min.css";
import { I18nProvider } from "i18n/I18nProvider";
import { KikiI18nProvider } from "i18n/I18nContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <KikiI18nProvider>
      <I18nProvider>
        <SettingsProvider>
          <KikiThemeProvider>
            <App />
          </KikiThemeProvider>
        </SettingsProvider>
      </I18nProvider>
    </KikiI18nProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
