import { ButtonContained, Flex, MenuPopover } from "@kikisoftware/uikit";
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { ButtonIconStyle } from "components/UrlBar";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { DEFAULT_SCREENSHOT_CONFIG } from "utils/configs";
import { CaptureScreenshotRequestFormat, ScreenShotProps, ScreenshotRef } from "utils/types";
import { useFarmContext } from "context/FarmContext";
import { downloadImageFromBase64 } from "utils/helpers";

const LabelSelectStyle = styled("div")({
  backgroundColor: "#F4F4F4",
  borderRadius: 4,
  padding: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  fontSize: 14,
  fontWeight: 600,
  color: "#212B36",
});

function ScreenShotPopover(props: any, ref: Ref<ScreenshotRef>) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [screenShotSettings, setScreenShotSettings] =
    useState<ScreenShotProps>(DEFAULT_SCREENSHOT_CONFIG);
  const { ws, captureScreenshot, getLayoutMetrics } = useFarmContext();

  useImperativeHandle(ref, () => ({
    downloadScreenshot,
  }));

  useEffect(() => {
    if (!ws) return;

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);

      if (json.result && json.result.data) {
        console.log("yyes");
      }
    };
  }, [ws]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeScreenshotFormat = (format: CaptureScreenshotRequestFormat) => () => {
    setScreenShotSettings((prev) => ({
      ...prev,
      imageFormat: format,
    }));
  };

  const changeScreenshotFullScreen = (value: boolean) => () => {
    setScreenShotSettings((prev) => ({
      ...prev,
      fullScreen: value,
    }));
    if (value) {
      getLayoutMetrics();
    }
  };

  const requestDownloadScreenCapture = () => {
    captureScreenshot(screenShotSettings);
  };

  const downloadScreenshot = (base64Data: string) => {
    console.log({ base64Data });
    downloadImageFromBase64(
      base64Data,
      `image/${screenShotSettings.imageFormat}`,
      `screen_capture.${screenShotSettings.imageFormat}`,
    );
  };

  return (
    <>
      <ButtonIconStyle
        startIcon={<CameraAltOutlinedIcon sx={{ color: "#637380" }} />}
        sx={{ mx: 1 }}
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Typography variant="textSm" color="#637380">
          Screenshot
        </Typography>
      </ButtonIconStyle>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400, px: 1.5, pt: 1, pb: 1.5 }}
      >
        <Flex sx={{ border: "1px solid #F4F4F4", mt: 1, p: 1, borderRadius: 1, gap: 3.75 }}>
          <Box width={1 / 2}>
            <Typography variant="textSm">Định dạng ảnh</Typography>

            <LabelSelectStyle>
              <LabelSelectStyle
                sx={{
                  width: 1 / 2,
                  height: 32,
                  cursor: "pointer",
                  bgcolor:
                    screenShotSettings.imageFormat === CaptureScreenshotRequestFormat.Jpeg
                      ? "white"
                      : "transparent",
                }}
                onClick={changeScreenshotFormat(CaptureScreenshotRequestFormat.Jpeg)}
              >
                JPG
              </LabelSelectStyle>
              <LabelSelectStyle
                sx={{
                  width: 1 / 2,
                  height: 32,
                  cursor: "pointer",
                  bgcolor:
                    screenShotSettings.imageFormat === CaptureScreenshotRequestFormat.Png
                      ? "white"
                      : "transparent",
                }}
                onClick={changeScreenshotFormat(CaptureScreenshotRequestFormat.Png)}
              >
                PNG
              </LabelSelectStyle>
            </LabelSelectStyle>
          </Box>

          <Box width={1 / 2}>
            <Typography variant="textSm">Chụp toàn màn hình</Typography>

            <LabelSelectStyle>
              <LabelSelectStyle
                sx={{
                  width: 1 / 2,
                  height: 32,
                  cursor: "pointer",
                  bgcolor: screenShotSettings.fullScreen ? "white" : "transparent",
                }}
                onClick={changeScreenshotFullScreen(true)}
              >
                Có
              </LabelSelectStyle>
              <LabelSelectStyle
                sx={{
                  width: 1 / 2,
                  height: 32,
                  cursor: "pointer",
                  bgcolor: !screenShotSettings.fullScreen ? "white" : "transparent",
                }}
                onClick={changeScreenshotFullScreen(false)}
              >
                Không
              </LabelSelectStyle>
            </LabelSelectStyle>
          </Box>
        </Flex>
        <ButtonContained
          startIcon={<FileDownloadOutlinedIcon />}
          fullWidth
          sx={{ mt: 1 }}
          onClick={requestDownloadScreenCapture}
        >
          Tải xuống
        </ButtonContained>
      </MenuPopover>
    </>
  );
}

export default forwardRef(ScreenShotPopover);
