import { setupFarmLiveView } from "utils/setupFarmLiveView";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BrowserTabProps, ScreenshotRef } from "utils/types";
import { wsMethod } from "utils/configs";
import { getCurrentBrowserTabId, getCurrentWsHost } from "utils/helpers";
import { farmServices } from "services";
import { differenceBy, isArray } from "lodash";

export function farmLiveViewListener() {
  const [currentBrowserTab, setCurrentBrowserTab] = React.useState<string | undefined>(
    getCurrentBrowserTabId(),
  );
  const [isFocusing, setIsFocusing] = useState<boolean>(true);
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false);
  const [frameImg, setFrameImage] = useState<string | undefined>();
  const [browserTabs, setBrowserTabs] = useState<BrowserTabProps[]>([]);
  const [query] = useSearchParams();

  const screenshotRef = useRef<ScreenshotRef>(null);
  const interval = useRef<ReturnType<typeof setInterval>>();

  const {
    ws,
    isCasting,
    navigationHistory,
    setNavigationHistory,
    startCasting,
    listenScreenCastChange,
    screenCastFrame,
    reloadTab,
    enableNetwork,
    enablePage,
    // focusActiveTab,
    stopCasting,
    resendWithSessionId,
    getNavigationHistory,
    initMouse,
    closeTarget,
    focusActiveTab,
    createTarget,
    farmPermission,
    isTabCreated,
    setIsTabCreated,
    setTabIsNavigating,
    setLayoutMetrics,
    getLayoutMetrics,
    ...others
  } = setupFarmLiveView();
  const navigate = useNavigate();

  useEffect(() => {
    if (browserTabs.length === 0) return;
    if (browserTabs.findIndex((tab) => tab.id === getCurrentBrowserTabId()) === -1) {
      console.log({ brow: browserTabs, cur: getCurrentBrowserTabId() });
      setCurrentBrowserTab(browserTabs[0].id);
      changeTab(browserTabs[0].id);
    }
  }, [browserTabs]);

  useEffect(() => {
    getBrowserTabs(true);
    interval.current = setInterval(() => {
      getBrowserTabs();
    }, 3000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const onBrowserTabChange = (tab: string) => {
    setCurrentBrowserTab(tab);
  };

  const getBrowserTabs = async (isFistTime?: boolean) => {
    try {
      const browserTabsResults = await farmServices.getListBrowserTabs();
      if (isFistTime) {
        setBrowserTabs(browserTabsResults.map((tab, idx) => ({ ...tab, index: idx })));
      } else {
        setBrowserTabs((prevBrowserTabs) => {
          console.log("prev", { prevBrowserTabs, browserTabsResults });
          if (browserTabsResults.length < prevBrowserTabs.length) {
            return prevBrowserTabs.filter(
              (tab) => browserTabsResults.findIndex((t) => t.id === tab.id) !== -1 && !tab.isDraft,
            );
          }
          const withoutDraft = prevBrowserTabs.filter((tab) => !tab.isDraft);
          const differenceTabs = differenceBy(browserTabsResults, withoutDraft, "id");
          const intersectionTabs = withoutDraft.map((tab) => ({
            ...browserTabsResults.find((t) => t.id === tab.id),
            index: tab.index,
          })) as BrowserTabProps[];
          console.log({ withoutDraft, differenceTabs, intersectionTabs });
          return [...intersectionTabs, ...differenceTabs]
            .map((tab, idx) => ({
              ...tab,
              index: idx,
            }))
            .sort((a, b) => (a.index as number) - (b.index as number));
        });
      }
    } catch (err) {
      clearInterval(interval.current);
      setIsDisconnected(true);
    }
  };

  console.log("browserTabs", browserTabs);

  useEffect(() => {
    if (!ws) return;

    ws.onopen = () => {
      console.log("open");
      enableNetwork();
      enablePage();
      focusActiveTab();
      startCasting();
      getNavigationHistory();
      initMouse();
      getLayoutMetrics();
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      console.log("json", json);
      if (json.method === wsMethod.pageScreencastFrame) {
        setFrameImage(screenCastFrame(json.params.data, json.params.metadata));
        listenScreenCastChange(json.params.sessionId);
      }

      if (json.method === wsMethod.pageScreencastVisibilityChanged) {
        setIsFocusing(json.params.visible);
        // getBrowserTabs();
      }

      if (json.method === wsMethod.targetAttachedToTarget) {
        resendWithSessionId(json.params.sessionId);
      }

      if (json.method === "CSS.styleSheetAdded") {
        // ws.send(
        //   JSON.stringify({
        //     id: 788,
        //     method: "Network.loadNetworkResource",
        //     params: {
        //       frameId: json.params.header.frameId,
        //       url: "https://www.youtube.com/yt-swatch-behavior_custom_style.css.map",
        //       options: { disableCache: true, includeCredentials: true },
        //     },
        //   }),
        // );
      }

      if (json.result && isArray(json.result.entries)) {
        setNavigationHistory(json.result);
      }

      if (json.result && json.result.data) {
        if (screenshotRef && screenshotRef.current) {
          screenshotRef.current.downloadScreenshot(json.result.data);
        }
      }

      if (json.method === wsMethod.targetTargetCreated) {
        if (isTabCreated) {
          const newTabId = json.params.targetInfo.targetId;
          changeTab(newTabId);
          setCurrentBrowserTab(newTabId);
          // setBrowserTabs((prev) => [...prev, { ...defaultTab, id: newTabId }]);
          setIsTabCreated(false);
        }
      }

      if (json.method === wsMethod.networkLoadingFinished) {
        setTabIsNavigating(undefined);
        getNavigationHistory();
      }

      if (json.result && json.result.contentSize && json.result.cssLayoutViewport) {
        setLayoutMetrics(json.result);
      }
    };

    ws.onclose = function () {
      if (isCasting) {
        setIsDisconnected(true);
      }
    };

    ws.onerror = function () {
      console.log("WError");
    };
  }, [ws, isCasting, isTabCreated]);

  const changeTab = (tabId: string) => {
    console.log(getCurrentWsHost());
    stopCasting();
    const search = query.get("token")
      ? getCurrentWsHost() + tabId + "&token=" + encodeURIComponent(query.get("token") as string)
      : getCurrentWsHost() + tabId;
    navigate({
      pathname: "/",
      search: search,
    });
  };

  const closeTab = (tabId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!farmPermission.control) return;
    closeTarget(tabId);
    const updatedListTabs = browserTabs.filter((tab) => tab.id !== tabId);
    setBrowserTabs(updatedListTabs);
    console.log({ currentBrowserTab, tabId });
    if (currentBrowserTab === tabId && updatedListTabs.length > 0) {
      // changeTab(updatedListTabs[0].id);
    }
  };

  const openNewTab = () => {
    createTarget();
    getBrowserTabs();
  };

  return {
    ws,
    frameImg,
    navigationHistory,
    reloadTab,
    isFocusing,

    isDisconnected,
    browserTabs,
    changeTab,
    getNavigationHistory,
    screenshotRef,
    initMouse,
    closeTab,
    currentBrowserTab,
    onBrowserTabChange,
    farmPermission,
    createTarget: openNewTab,
    getLayoutMetrics,
    ...others,
  };
}
