import React from "react";
import { Divider, Stack, styled, Typography } from "@mui/material";
import { Image } from "@kikisoftware/uikit";
import { useFarmContext } from "context/FarmContext";

const FooterRootStyle = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 18px",
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
});

const StatusIcon = styled("div")({
  display: "flex",
  width: 16,
  height: 16,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(40, 167, 69, 0.08)",
});

function Footer() {
  const { tokenSettings, isDisconnected } = useFarmContext();
  return (
    <FooterRootStyle>
      <Stack
        direction="row"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1.5}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <StatusIcon>
            <StatusIcon
              sx={{ width: 8, height: 8, bgcolor: isDisconnected ? "#DC3545" : "#28A745" }}
            />
          </StatusIcon>
          <Typography variant="textSm">{isDisconnected ? "Disconnected" : "Connected"}</Typography>
        </Stack>
        <Typography variant="textSm">Email: {!isDisconnected && tokenSettings.email}</Typography>
        <Typography variant="textSm">
          Profile: {!isDisconnected && tokenSettings.profileName}
        </Typography>
      </Stack>
      <Image src="/statics/icons/kikilogin.svg" asyncLoading={false} />
    </FooterRootStyle>
  );
}

export default Footer;
