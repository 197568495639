import { useEffect, useState } from "react";

export default function useWs(url?: string) {
  const [ws, setWs] = useState<WebSocket | undefined>();
  useEffect(() => {
    if (!url) return;
    console.log(url);
    const newWs = new WebSocket(url);
    setWs(newWs);
  }, [url]);

  return ws;
}
